import React from 'react';
import logo from './images/cosmo logo.jpg'; 
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div>
      <footer className="w3l-footer-29-main">
        <div className="footer-29 py-5">
          <div className="container">
            <div className="row footer-top-29">
              <div className="col-lg-3 col-md-6 footer-list-29 footer-1 pe-lg-5">
                <div className="bottom-copies">
                  <h2>
                    <a className="navbar-brand" href="index.html">
                      <img src={logo} alt="Logo" style={{ marginLeft: '50px', height: '250px', width: '180px' }} />
                    </a>
                  </h2>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 footer-list-29 footer-3 mt-lg-0 mt-3">
                <div className="clinic-address text-center mt-3">
                  <h4 style={{ color: 'white'}}>Our Hospital Address:</h4>
                  <p>Cosmoderm Ayurved,
                    Swatantrya Chowk - Pande Chowk Rd, near Rupali hotel, next to Ashish play school,
                    Jilha Peth, Prabhat Colony,
                    Jalgaon, Maharashtra 425001
                  </p>
                  <br />
                  <p><h4 style={{ color: 'white'}} >Renukai Hospital address:</h4>
                    Plot no 68, Gut no 80/1/2/A,
                    Asha Baba Nagar, Below Shiv Colony,
                    Jalgaon, Maharashtra 425001
                  </p>
                </div>
                <p className="copy-footer-29 text-center" style={{ color: 'white' }}><br />
                  © 2024 COSMO. All rights reserved | Designed by Shree Digital Marketing Agency And Dipali Marathe
                </p>
              </div>
              <div className="col-lg-4 col-md-6 footer-list-29 mt-lg-0 mt-4">
                <div className="main-social-footer-29">
                  <Link to="#facebook" className="facebook"><span className="fab fa-facebook-f"></span></Link>
                  <Link to="#twitter" className="twitter"><span className="fab fa-twitter"></span></Link>
                  <Link to="https://www.instagram.com/cosmo.dermayurved/?igsh=MWdpbmpkdTRzbm03MA%3D%3D" className="instagram"><span className="fab fa-instagram"></span></Link>
                  <Link to="#linkd" className="linkd"><span className="fab fa-linkedin-in"></span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button id="movetop" title="Go to top" style={{ display: 'none' }}>
          <span className="fa fa-angle-up"></span>
        </button>
      </footer>
    </div>
  );
}

export default Footer;
