import React, { useState } from 'react';
import './Contact.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, phone, subject, message } = formData;
        const whatsappMessage = `Name: ${name}\nEmail: ${email}\nPhone: ${phone}\nSubject: ${subject}\nMessage: ${message}`;
        const whatsappURL = `https://wa.me/8956363770?text=${encodeURIComponent(whatsappMessage)}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <>
            <div className="inner-banner py-5">
                <section className="w3l-breadcrumb text-left py-sm-5">
                    <div className="container">
                        <div className="w3breadcrumb-gids">
                            <div className="w3breadcrumb-left text-left">
                                <h2 className="inner-w3-title">Contact Us</h2>
                                <p className="inner-page-para mt-2">Cosmoderm Ayurved Hospital</p>
                            </div>
                            <div className="w3breadcrumb-right">
                                <ul className="breadcrumbs-custom-path">
                                    <li><a href="index.html">Home</a></li>
                                    <li className="active">
                                        <span className="fas fa-angle-double-right mx-2"></span> Contact Us
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="w3l-contact-2 py-5" id="contact">
                <div className="container py-lg-4 py-md-3 py-2">
                    <div className="title-content text-center">
                        <h6 className="title-subw3hny mb-1">Get in touch</h6>
                        <h3 className="title-w3l mb-5">Contact with our support <br /> during emergency!</h3>
                    </div>

                    <div className="contact-grids d-grid mt-5 pt-lg-3">
                        <div className="contact-left">
                            <div className="cont-details">
                                <div className="cont-top margin-up">
                                    <div className="cont-left text-center">
                                        <span className="fas fa-map-marker-alt"></span>
                                    </div>
                                    <div className="cont-right">
                                        <h6>Our Hospital address:</h6>
                                        <p>Cosmoderm Ayurved, Swatantrya Chowk - Pande Chowk Rd, near Rupali hotel, next to Ashish play school, Jilha Peth, Prabhat Colony, Jalgaon, Maharashtra 425001</p>
                                    </div>
                                </div>
                                <div className="cont-top margin-up">
                                    <div className="cont-left text-center">
                                        <span className="fas fa-map-marker-alt"></span>
                                    </div>
                                    <div className="cont-right">
                                        <h6>Renukai Hospital address:</h6>
                                        <p>Plot no 68, Gut no 80/1/2/A, Asha Baba Nagar, Below Shiv Colony Jalgaon</p>
                                    </div>
                                </div>
                                <div className="cont-top margin-up">
                                    <div className="cont-left text-center">
                                        <span className="fas fa-phone-alt"></span>
                                    </div>
                                    <div className="cont-right">
                                        <h6>Call for help:</h6>
                                        <p><a href="tel:+8956363770">08956363770</a></p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="contact-right">
                            <form onSubmit={handleSubmit} className="signin-form">
                                <div className="input-grids">
                                    <input 
                                        type="text" 
                                        name="name" 
                                        id="w3lName" 
                                        placeholder="Your Name*" 
                                        className="contact-input" 
                                        value={formData.name}
                                        onChange={handleChange}
                                        required 
                                    />
                                    <input 
                                        type="email" 
                                        name="email" 
                                        id="w3lSender" 
                                        placeholder="Your Email*" 
                                        className="contact-input" 
                                        value={formData.email}
                                        onChange={handleChange}
                                        required 
                                    />
                                    <input 
                                        type="text" 
                                        name="phone" 
                                        id="w3lPhone" 
                                        placeholder="Enter your Phone Number *" 
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required 
                                    />
                                    <input 
                                        type="text" 
                                        name="subject" 
                                        id="w3lSubect" 
                                        placeholder="Subject*" 
                                        className="contact-input" 
                                        value={formData.subject}
                                        onChange={handleChange}
                                        required 
                                    />
                                </div>
                                <div className="form-input">
                                    <textarea 
                                        name="message" 
                                        id="w3lMessage" 
                                        placeholder="Type your message here*" 
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    ></textarea>
                                </div>
                                <div className="submit-w3l-button text-lg-right">
                                    <button className="btn btn-style btn-primary" type="submit">Send Message</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="map-iframe mt-5 pt-lg-3">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59595.44158872296!2d75.51434482577058!3d21.00405449999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd90fe4c1c15e31%3A0xa660315ed5cd1f6b!2sCosmoderm%20Ayurved%20(Dr%20Renuka%20Raje%20and%20Dr%20Sarang%20Joshi)!5e0!3m2!1sen!2sin!4v1722847360586!5m2!1sen!2sin"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            className="map-iframe"
                        ></iframe>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
