import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from './images/cosmo logo.jpg'; 

function Header() {
  return (
    <header className="fixed-top">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light stroke py-lg-0" >
          
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Logo" className="img-fluid" /> 
          </Link>
          
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon fa icon-expand fa-bars"></span>
            <span className="navbar-toggler-icon fa icon-close fa-times"></span>
          </button>
          
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav me-lg-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/" >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link"  to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link"  to="/product">
                  Product
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>

            <ul className="header-search me-lg-4">
              <li className="nav-item search-right">
                <Link to="/contact" className="btn search-btn btn-primary" title="services">
                 know More <span className="fas fa-search ms-3" aria-hidden="true"></span>
                </Link>
               
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
