import React from 'react';
import ss14 from './images/Fungal Infection Treatment.jpg';
import ss15 from './images/art.jpg';
import ss16 from './images/Tinea Infections Treatment.jpg';
import ss17 from './images/Vitiligo Treatment.jpg';
import sk1 from './images/chikenpox.jpeg';
import sk2 from './images/Psoriasis.jpg';
import sk3 from './images/egma.jpeg';

function SkinTreatments() {
  return (
    <div className="service-section py-5">
      <div className="container">
        <h3 className="section-title text-center mb-4">Skin Treatments</h3>
        <div className="row text-center">
          {/* Fungal Infection Treatment */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="grids5-info">
              <a className="d-block zoom">
                <img src={ss14} alt="Fungal Infection Treatment" className="img-fluid radius-image service-img" />
              </a>
              <div className="blog-info">
                <h4>Fungal Infection Treatment (फंगल इन्फेक्शन)</h4>
              </div>
            </div>
          </div>

          {/* Articaria Treatment */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="grids5-info">
              <a className="d-block zoom">
                <img src={ss15} alt="Articaria Treatment" className="img-fluid radius-image service-img" />
              </a>
              <div className="blog-info">
                <h4>Articaria Treatment (शितपित्त)</h4>
              </div>
            </div>
          </div>

          {/* Tinea Infections Treatment */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="grids5-info">
              <a className="d-block zoom">
                <img src={ss16} alt="Tinea Infections Treatment" className="img-fluid radius-image service-img" />
              </a>
              <div className="blog-info">
                <h4>Tinea Infections Treatment (दाद)</h4>
              </div>
            </div>
          </div>


          {/* Vitiligo Treatment */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="grids5-info">
              <a className="d-block zoom">
                <img src={ss17} alt="Vitiligo Treatment" className="img-fluid radius-image service-img" />
              </a>
              <div className="blog-info">
                <h4>Vitiligo Treatment (श्वित्र)</h4>
              </div>
            </div>
          </div>

          {/* Chickenpox */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="grids5-info">
              <a className="d-block zoom">
                <img src={sk1} alt="Chickenpox Treatment" className="img-fluid radius-image service-img" />
              </a>
              <div className="blog-info">
                <h4>Chickenpox</h4>
              </div>
            </div>
          </div>

          {/* Psoriasis */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="grids5-info">
              <a className="d-block zoom">
                <img src={sk2} alt="Psoriasis Treatment" className="img-fluid radius-image service-img" />
              </a>
              <div className="blog-info">
                <h4>Psoriasis</h4>
              </div>
            </div>
          </div>

          {/* Eczema */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="grids5-info">
              <a className="d-block zoom">
                <img src={sk3} alt="Eczema Treatment" className="img-fluid radius-image service-img" />
              </a>
              <div className="blog-info">
                <h4>Eczema</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkinTreatments;
