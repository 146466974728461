import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About'; // Import the About component
import Header from './Header';
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';
import SkinTreatments from './Skintreatment';
import Hairtreament from './Hairtreament';
import Panchkarma from './Panchkarma';
import Product from './Product';


function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home should be the default path */}
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/skintreatment" element={<SkinTreatments />} />
        <Route path="/hairtreatment" element={<Hairtreament />} />
        <Route path="/product" element={<Product />} />
        
        <Route path="/panchkarma" element={<Panchkarma/>} />
        
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
