import React from 'react'
import ss2 from './images/NAVARAKIZI.webp';
import ss3 from './images/SHIRODHARA.webp';
import ss4 from './images/NASYA.webp';
import ss7 from './images/karna.webp';
import ss8 from './images/pristha.webp';
import ss12 from './images/Dandruff.png';
import ss13 from './images/laser-hair-removal1.jpg';
import ss18 from './images/steam.jpg'; 
import ss19 from './images/heart.jpeg';
import k1 from './images/kati-basti-img.jpg';
import k2 from './images/Janu Basti (Knee Care).jpg';
import k3 from './images/Maniya Basti (Neck Care).jpg';
import k33 from './images/Udvartana1.jpg';
import k4 from './images/Shirobasti.jpg';
import k5 from './images/heart.jpeg';
import k6 from './images/rakt.jpeg';
import k7 from './images/ear.png';
import k8 from './images/eye.jpeg';
import k9 from './images/Padabhanga (Foot Care).jpeg';

function Panchkarma() {
  return (
    <div>
    
    <div className="service-section py-5">
                            <h3 className="section-title text-center mb-4">Panchakarma Treatments</h3>
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss2} alt="NAVARAKIZI" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>NAVARAKIZI</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss3} alt="SHIRODHARA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>SHIRODHARA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss4} alt="NASYA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>NASYA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss7} alt="KARNA PURNA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>KARNA PURNA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss8} alt="PRISTHA ABHAYANGA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>PRISTHA ABHAYANGA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss12} alt="Oil Massage Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Oil Massage Therapy</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss13} alt="Agnikarma" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Agnikarma</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss18} alt="Steam (Swedan)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Steam (Swedan)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss19} alt="Heart Care (Hrida Basti)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Heart Care (Hrida Basti)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k1} alt="Kati Basti (Spine Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Kati Basti (Spine Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k2} alt="Janu Basti (Knee Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Janu Basti (Knee Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k3} alt="Maniya Basti (Neck Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Maniya Basti (Neck Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k33} alt="Udvartana" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Udvartana</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k4} alt="Shirobasti" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Shirobasti</h4>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k6} alt="Sisirlep" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Raktamokshana</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k7} alt="Kurna Purna (Ear Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Kurna Purna (Ear Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k8} alt="Netra Tarpan (Eye Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Netra Tarpan (Eye Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k9} alt="Padabhanga (Foot Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Padabhanga (Foot Care)</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>




    </div>
  )
}

export default Panchkarma