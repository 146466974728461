import React from 'react';
import ss1 from './images/Face.jpeg';
import ss2 from './images/NAVARAKIZI.webp';
import ss3 from './images/SHIRODHARA.webp';
import ss4 from './images/NASYA.webp';
import ss7 from './images/karna.webp';
import ss8 from './images/pristha.webp';
import ss9 from './images/PATRA-POTTALI.webp';
import ss11 from './images/Hair.jpg';
import ss12 from './images/Dandruff.png';
import ss13 from './images/laser-hair-removal1.jpg';
import H1 from './images/Premature Greying1.webp';
import H2 from './images/alo.jpg';
import H3 from './images/Hair Loss Treatment.jpg';
import ss14 from './images/Fungal Infection Treatment.jpg';
import ss15 from './images/art.jpg';
import ss16 from './images/Tinea Infections Treatment.jpg';
import ss17 from './images/Vitiligo Treatment.jpg';
import ss18 from './images/steam.jpg'; 
import ss19 from './images/heart.jpeg';
import k1 from './images/kati-basti-img.jpg';
import k2 from './images/Janu Basti (Knee Care).jpg';
import k3 from './images/Maniya Basti (Neck Care).jpg';
import k33 from './images/Udvartana1.jpg';
import k4 from './images/Shirobasti.jpg';
import k5 from './images/heart.jpeg';
import k6 from './images/rakt.jpeg';
import k7 from './images/ear.png';
import k8 from './images/eye.jpeg';
import k9 from './images/Padabhanga (Foot Care).jpeg';
import sk1 from './images/chikenpox.jpeg';
import sk2 from './images/Psoriasis.jpg';
import sk3 from './images/egma.jpeg';
import './services.css'
import { Link } from 'react-router-dom';

 // Example image for Heart Care

const Services = () => {
    return (
        <>
            <div className="inner-banner py-5">
                <section className="w3l-breadcrumb text-left py-sm-5">
                    <div className="container">
                        <div className="w3breadcrumb-gids">
                            <div className="w3breadcrumb-left text-left">
                                <h2 className="inner-w3-title">Our Services</h2>
                                <p className="inner-page-para mt-2">Tailored Beauty and Wellness Solutions</p>
                            </div>
                            <div className="w3breadcrumb-right">
                                <ul className="breadcrumbs-custom-path">
                                    <li><Link to="index.html">Home</Link></li>
                                    <li className="active">
                                        <span className="fas fa-angle-double-right mx-2"></span> Services
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="w3l-services-1 py-5" id="grids-3">
                <div className="container py-md-5 py-2">
                    <div className="row mb-5 pb-lg-4">
                        <div className="services-1-right col-lg-6 mt-lg-0 mt-md-5 mt-4 pe-lg-5">
                            <div className="title-content-two text-left">
                                <h6 className="title-subw3hny mb-1">Our Expertise</h6>
                                <h3 className="title-w3l mb-3">Explore Our Comprehensive Treatments</h3>
                            </div>
                            <p>
                                At Cosmoderm Ayurved Hospital, we offer a wide range of personalized treatments designed to enhance your natural beauty and well-being. From rejuvenating facials to advanced body treatments, our services are tailored to meet your unique needs and preferences.
                                Discover the difference of expert care and cutting-edge technology in a tranquil setting.
                            </p>
                            <Link to="/contact" className="btn btn-style btn-primary mt-lg-5 mt-4">Learn More</Link>
                        </div>
                        <div className="col-lg-6 p5-lg-4 services-1-left">
                            <img src={ss1} alt="Cosmodermic Clinic Services" className="img-fluid radius-image" />
                        </div>
                    </div>
                </div>
            </div>

            <section className="w3l-services-2" id="services1">
                <div className="midd-w3 py-5">
                    <div className="container py-lg-5 py-md-3">
                        <div className="serve-title-header text-center mx-auto">
                            <h6 className="title-subw3hny mb-1">What We Offer</h6>
                            <h3 className="title-w3l mb-3">Innovative Treatments & Techniques</h3>
                            <p className="abinfo-para">
                                Our services include the latest advancements in skincare and wellness. Each treatment is designed to provide you with visible results and a relaxing experience. From luxurious facials to expert body care, we are committed to delivering exceptional service and care.
                            </p>
                        </div>
                        
              
                        <div className="service-section py-5">
                            <h3 className="section-title text-center mb-4">Panchakarma Treatments</h3>
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss2} alt="NAVARAKIZI" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>NAVARAKIZI</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss3} alt="SHIRODHARA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>SHIRODHARA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss4} alt="NASYA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>NASYA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss7} alt="KARNA PURNA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>KARNA PURNA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss8} alt="PRISTHA ABHAYANGA" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>PRISTHA ABHAYANGA</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss12} alt="Oil Massage Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Oil Massage Therapy</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss13} alt="Agnikarma" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Agnikarma</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss18} alt="Steam (Swedan)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Steam (Swedan)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss19} alt="Heart Care (Hrida Basti)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Heart Care (Hrida Basti)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k1} alt="Kati Basti (Spine Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Kati Basti (Spine Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k2} alt="Janu Basti (Knee Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Janu Basti (Knee Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k3} alt="Maniya Basti (Neck Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Maniya Basti (Neck Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k33} alt="Udvartana" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Udvartana</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k4} alt="Shirobasti" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Shirobasti</h4>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k6} alt="Sisirlep" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Raktamokshana</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k7} alt="Kurna Purna (Ear Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Kurna Purna (Ear Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k8} alt="Netra Tarpan (Eye Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Netra Tarpan (Eye Care)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={k9} alt="Padabhanga (Foot Care)" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Padabhanga (Foot Care)</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Hair Treatments Section */}
                        <div className="service-section py-5">
                            <h3 className="section-title text-center mb-4">Hair Treatments</h3>
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss11} alt="Hair PRP Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Hair PRP Treatment</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss12} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4> Anti Dandruff Therapy</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={H1} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4> Premature Greying</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={H2} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Alopecia</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={H3} alt="Dandruff Therapy" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Hair Loss Treatment</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss13} alt="Laser Hair Reduction" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Laser Hair Reduction</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Skin Treatments Section */}
                        <div className="service-section py-5">
                            <h3 className="section-title text-center mb-4">Skin Treatments</h3>
                            <div className="row text-center">
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss14} alt="Fungal Infection Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Fungal Infection Treatment (फंगल इन्फेक्शन)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss15} alt="Articaria Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Articaria Treatment (शितपित्त)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss16} alt="Tinea Infections Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Tinea Infections Treatment (दाद)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={ss17} alt="Vitiligo Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Vitiligo Treatment (श्वित्र)</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={sk1} alt="Tinea Infections Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Chikenpox</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={sk2} alt="Tinea Infections Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Psoriasis</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="grids5-info">
                                        <a className="d-block zoom">
                                            <img src={sk3} alt="Tinea Infections Treatment" className="img-fluid radius-image service-img" />
                                        </a>
                                        <div className="blog-info">
                                            <h4>Eczama</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                                               {/* Additional Treatments Section */}
                     <div className="service-section py-5" style={{ backgroundColor: '#f8f9fa' }}>
                            <h3 className="section-title text-center mb-4" style={{ fontSize: '2rem', fontWeight: '600', color: '#343a40' }}>Additional Treatments</h3>
                            <div className="container">
                                <div className="row text-center">
                                <div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Pediatric Care</h4>
        <p>Comprehensive pediatric care, including Suvarnaprashan for boosting immunity in children.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Agni Karma</h4>
        <p>Therapeutic heat treatments for chronic pain and joint disorders.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Treatment for Warts, Moles, and Blemishes</h4>
        <p>Effective treatments for various skin issues including warts, moles, and blemishes.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Menstrual Health Treatments</h4>
        <p>Ayurvedic remedies for menstrual health and related issues.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Mental Counseling</h4>
        <p>Counseling services for mental wellness and stress management.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Pitta Dosha Treatment</h4>
        <p>Treatments for balancing pitta dosha and managing acidity-related issues.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Marma Therapy</h4>
        <p>Specialized therapy focusing on vital energy points for holistic healing.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Weight Management</h4>
        <p>Personalized diet and lifestyle guidance for weight management.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Bone Disorders and Treatment</h4>
        <p>Ayurvedic treatments for bone disorders and joint care.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Fertility Issues and Treatment</h4>
        <p>Treatments and counseling for fertility issues.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Kidney Disorders (Kidney Stones)</h4>
        <p>Effective remedies for kidney stones and other urinary disorders.</p>
    </div>
</div>
<div className="col-lg-6 col-md-6 mb-4">
    <div className="grids5-info p-4" style={{ backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <h4>Diet and Lifestyle Guidance</h4>
        <p>Guidance on diet and lifestyle for overall well-being.</p>
    </div>
</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Services;
